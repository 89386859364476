import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { lastValueFrom, map, Subject, take } from "rxjs";
import { AppConfig } from "../../app.config";
import { PdfViewerDialogComponent } from "../../shared/dialogs/pdf-viewer-dialog/pdf-viewer-dialog.component";
import { MediaService } from "../media/media.service";
import { AliceKeyValueConfig, AliceMessage, AliceSession, AliceTrace, AliceTraceFilter, AliceTraceSummary, Message } from "./alice.interface";
import { fetchEventSource } from "@microsoft/fetch-event-source";

interface AliceMessageStream {
  text?: string;
  type?: string;
  message?: string;
  data?: string;
  session?: AliceSession;
  object?: AliceMessage;
  overwrite?: boolean;
  error?: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class AliceService {

  private service = AppConfig.data['services']['alice'];
  private endpoint = `${this.service}`;
  private currentPageId: string;
  private currentMediaIds: string[] = [];
  private currentHomepageId: string;
  private currentPageFlowsurvey: string;

  constructor(
    private http: HttpClient,
    private mediaService: MediaService,
    private dialog: MatDialog
  ) { }

  getSessions() {
    return this.http.get<AliceSession[]>(`${this.endpoint}/session`);
  }

  initiateSession() {
    return this.http.post<AliceSession>(`${this.endpoint}/session/create`, { title: 'Chat' });
  }

  initiateSessionWithPrompt(prompt: string) {
    return this.http.post<AliceSession>(`${this.endpoint}/session/initiateWithPrompt`, { prompt });
  }

  getSessionMessage(id: string) {
    return this.http.get<AliceMessage[]>(`${this.endpoint}/session/${id}/messages`);
  }

  getLastActiveSession() {
    return this.http.get<AliceSession>(`${this.endpoint}/session/lastActive`);
  }

  sendMessage(id: string, message: AliceMessage) {
    return this.http.post<AliceMessage>(`${this.endpoint}/session/${id}/message`, message);
  }

  likeMessage(id: string, comment: string) {
    return this.http.put(`${this.endpoint}/session/message/${id}/like`, { comment });
  }

  dislikeMessage(id: string, comment: string) {
    return this.http.put(`${this.endpoint}/session/message/${id}/dislike`, { comment });
  }

  sendMessageStream(sessionId: string, prompt: string, messageObj: AliceMessage) {
    const homepageId = localStorage.getItem('homeId');
    const pageId = this.currentPageId;
    const flowsurveyId = this.currentPageFlowsurvey;
    const mediaIds = this.currentMediaIds?.reduce((acc: string[], next) => acc.includes(next) ? acc : [...acc, next], []);

    const subject = new Subject<AliceMessageStream>();
    const token = localStorage.getItem('accessToken');
    let messagesConcat = '';
    let completed = false;

    fetchEventSource(`${this.endpoint}/stream/chatbot`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ prompt, homepageId, pageId, mediaIds, sessionId, flowsurveyId }),
      onmessage: (message: any) => {
        try {
          const data = JSON.parse(message.data);

          if (data.overwrite) {
            subject.next(data);

            return;
          } else if (data.completed) {
            completed = true;
            messageObj.answer = messagesConcat;
            messageObj.traceId = data.trace;

            if (data.messageSummary) {
              subject.next({
                text: null,
                object: data.messageSummary
              })

              subject.complete();
            } else {
              if (!messageObj.answer || !messageObj.prompt) return;

              this.sendMessage(sessionId, messageObj).subscribe({
                next: (message) => {
                  subject.next({
                    text: null,
                    object: message
                  });

                  subject.complete();
                },
                error: (error) => {
                  console.error(error);
                  subject.complete();
                }
              });
            }

            return;
          } else if (data.type === "VIDEO_ID") {
            subject.next({
              type: 'LOAD_VIDEO',
              message: data.id
            });

            return;
          } else if (data.type === 'FLOWSURVEY') {
            subject.next({
              type: 'LOAD_FLOWSURVEY',
              message: data.id
            })

            return;
          }
        } catch (e) {

        }

        if (!message.data) return;

        const data = message.data.replaceAll('{{space}}', ' ').replaceAll('{{linebreak}}', '\n');
        messagesConcat += data;

        subject.next({
          data,
          text: messagesConcat,
          object: null
        });

      },
      onerror: (_) => {
        if (completed) {
          return;
        }

        subject.next({
          message: "Não foi possível atender a sua requisição no momento. Por favor, tente novamente mais tarde.",
          overwrite: true,
          error: true
        })

        subject.complete();

        throw _;
      }
    })

    return subject.asObservable();
  }

  getAliceKeyValueConfig() {
    return this.http.get<AliceKeyValueConfig[]>(`${this.endpoint}/config/key-config`)
  }

  saveAliceKeyValueConfig(configs: AliceKeyValueConfig[]) {
    const removeEmptyExternalConnectorConfig = configs?.map(config => ({
      ...config,
      externalConnector: config?.externalConnector?.type != null ? config.externalConnector : null
    }))

    return this.http.put(`${this.endpoint}/config/key-config`, removeEmptyExternalConnectorConfig)
  }

  deleteSession(id: string) {
    return this.http.delete(`${this.endpoint}/session/${id}`);
  }

  deleteKeyConfig(id: string) {
    return this.http.delete(`${this.endpoint}/config/key-config/${id}`);
  }

  searchQuery(prompt: string) {
    return this.http.get(`${this.endpoint}/information?prompt=${prompt}`)
  }

  clickHandler(event): any {
    event?.preventDefault();

    if (event?.target?.tagName === 'A') {
      if (event?.target?.href?.includes('media')) {
        this.mediaService.getIamToken().pipe(take(1)).subscribe(iTk => {
          const mediaId = event?.target?.href?.split('=').pop();

          this.mediaService.getMedia(mediaId).subscribe({
            next: (media: any) => {
              this.mediaService.getCDNImage(media.url, iTk).subscribe({
                next: async (data: Blob) => {
                  const file = URL.createObjectURL(data);

                  if (media?.extension === 'pdf') {
                    this.dialog.open(PdfViewerDialogComponent, {
                      data: {
                        url: file,
                        blockCopy: 0
                      },
                      maxHeight: '99vh',
                      minWidth: '80vw',
                    })

                    return null;
                  } else {
                    window.open(file, '_blank');

                    return null;
                  }
                }
              });
            }
          });
        })
      } else if (event?.target?.href?.includes("/video/")) {
        const params = event?.target?.href.split('/');

        const mediaId = params[params.length - 2];
        const timestamp = params.pop();

        return { type: 'video', data: [mediaId, timestamp] };
      } else if (event?.target?.href?.includes("/flow/")) {
        const parts = event?.target?.href?.split('/');

        if (parts.length === 5) {
          const flowId = parts.pop();

          return { type: 'flowsurvey', data: { flowId } }
        } else if (parts.length === 6) {
          const flowId = parts[4];
          const nodeId = parts[5];

          return { type: 'flowsurvey', data: { flowId, nodeId } }
        }
      } else {
        window.open(event?.target?.href, '_blank');

        return null;
      }
    }
  }

  listTraces(filter: AliceTraceFilter, page: number = 0, size: number = 20, sort: string = "startedAt DESC") {
    return this.http.post<any>(`${this.endpoint}/v1/traces/query`, filter, { params: { page, size, sort } });
  }

  getTraceById(id: string) {
    return this.http.get<AliceTrace>(`${this.endpoint}/v1/traces/${id}`)
      .pipe(
        map(item => {
          if (item?.items?.length > 0) {
            const convertJson = (it: AliceTrace) => {
              const json = {
                ...it,
              }

              try {
                json.outputParsed = JSON.parse(json.output)
              } catch (e) {

              }

              try {
                json.inputParsed = JSON.parse(json.input)
              } catch (e) {

              }

              return json
            }

            const converted = {
              ...item,
              items: item.items.map(convertJson)
            }

            console.log(converted);

            return converted;
          } else {
            return item
          }
        })
      )
  }

  autoSuggest(prompt: string) {
    return this.http.get<string[]>(`${this.endpoint}/autoSuggest`, { params: { prompt } });
  }

  getFeedbackReport(filter: any) {
    return this.http.post(`${this.endpoint}/v1/traces/report`, filter);
  }

  getLikedMessages(page: number = 1, prompt = "", sort = "createdAt,desc") {
    return this.http.get(`${this.endpoint}/v1/liked-chatbot-messages`, { params: { page, prompt, sort } });
  }

  sendSuggestToAlice(prompt: string) {
    const subject = new Subject<AliceMessageStream>();
    const token = localStorage.getItem('accessToken');
    let messagesConcat = '';
    let completed = false;

    fetchEventSource(`${this.endpoint}/stream/test/chatbot`, {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ prompt }),
      onmessage: (message: any) => {
        try {
          const data = JSON.parse(message.data);

          if (data.overwrite) {
            subject.next(data);

            return;
          } else if (data.completed) {
            completed = true;

            return;
          }
        } catch (e) {

        }

        if (!message.data) return;

        const data = message.data.replaceAll('{{space}}', ' ').replaceAll('{{linebreak}}', '\n');
        messagesConcat += data;

        subject.next({
          data,
          text: messagesConcat,
          object: null
        });

      },
      onerror: (_) => {
        if (completed) {
          return;
        }

        subject.next({
          message: "Não foi possível atender a sua requisição no momento. Por favor, tente novamente mais tarde.",
          overwrite: true,
          error: true
        })

        subject.complete();

        throw _;
      }
    })

    return subject.asObservable();

  }

  editAutoSuggest(id: string, prompt: string) {
    return this.http.put(`${this.endpoint}/v1/liked-chatbot-messages/${id}`, { prompt });
  }

  deleteAutoSuggest(id: string) {
    return this.http.delete(`${this.endpoint}/v1/liked-chatbot-messages/${id}`);
  }

  getIntentionCloudItems(startAt: string, endAt: string) {
    return this.http.post<any>(`${this.endpoint}/v1/intention/cloud`, { startAt, endAt });
  }

  setCurrentPageId(pageId: string) {
    this.currentPageId = pageId;
  }

  setCurrentPageFlowsurvey(id: string) {
    this.currentPageFlowsurvey = id;
  }

  setCurrentMedias(mediaIds: string[]) {
    this.currentMediaIds = mediaIds;
  }

  setCurrentHomepageId(homeId) {
    this.currentHomepageId = homeId;
  }

  addCurrentMedias(media: string) {
    this.currentMediaIds.push(media);
  }

  clearCurrentIds() {
    this.currentPageId = null;
    this.currentMediaIds = [];
    this.currentPageFlowsurvey = null;
  }
}
