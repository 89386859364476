import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../app.config";
import { BehaviorSubject, defer, firstValueFrom, Observable, of, Subject, Subscription, throwError } from "rxjs";
import { Media, MediaSearchFilters, MediaContent, MediaShort, Tag } from "../../models/Media";
import { catchError, finalize, map, tap, throttleTime } from "rxjs/operators";
import { Utils } from '../../utils/utils'
import { isNaN } from "lodash";
import { LoggedUser } from "../../models/loggedUser.service";

@Injectable({ providedIn: 'root' })
export class MediaService {

  apiVersion = `v${AppConfig.data['apiVersion']}`;
  service = AppConfig.data['services']['media'];
  endpoint = `${this.service}`
  simple = `${this.service}`
  tagEndpoint = `${AppConfig.data['services']['tag']}/${this.apiVersion}`
  getMediaEndpoint = `${AppConfig.data['services']['getMedia']}/${this.apiVersion}`
  uploadMediaEndpoint = `${AppConfig.data['services']['uploadMedia']}/${this.apiVersion}`
  authservice = `${AppConfig.data['services']['auth']}`;

  token = localStorage.getItem('accessToken');
  iamToken$ = new BehaviorSubject(null);
  loading = false;
  lock = false;

  invalid$ = new BehaviorSubject(true);
  createMediaBase64$: BehaviorSubject<string> = new BehaviorSubject('');
  createMedia: BehaviorSubject<Media> = new BehaviorSubject({
    base64_complete: null,
    description: null,
    expiration_date: null,
    expiration_hour: null,
    id: null,
    publishing_date: null,
    publishing_hour: null,
    tags: null,
    title: null,
    width: null,
    height: null,
    original_size: null,
    larger_size: false,
    max_size: null,
    mimeTypeWithCodecs: null,
  } as Media);
  maxFileSizeConfig: any;

  private httpClient: HttpClient;

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private utils: Utils,
    private _user: LoggedUser,

    ) {
    this.httpClient = new HttpClient(handler);
    this.utils.setStorage('mdService', this.authservice)

    if(this._user.isAuthenticated()){
      this.getMaxFileSizeConfig().subscribe({
        next: (config) => this.maxFileSizeConfig = config
      })
    }

  }

  setCreateMediaBase64(base64: string) {
    this.createMediaBase64$.next(base64)
    this.createMedia.next({ ...this.createMedia.value, base64_complete: base64 })
  }

  setInvalid(status) {
    this.invalid$.next(status)
  }

  getInvalid() {
    return this.invalid$.asObservable()
  }

  getTags(q: string) {
    return this.http.get<Tag[]>(`${this.tagEndpoint}/autosuggest`, { params: { q } })
  }

  searchMedia(params: {
    query?: string;
    format?: string;
    per_page?: string;
    sort?: string;
    end_date?: string;
    start_date?: string;
    size?: any;
  }) {
    return this.http.get<MediaContent>(`${this.getMediaEndpoint}/resource_files`, { params })
  }

  setCreateMedia(media: Media) {
    this.createMedia.next({ ...this.createMedia.value, ...media })
  }

  getCreateMediaBase64() {
    return this.createMediaBase64$.asObservable()
  }

  newCreateMedia() {
    this.createMedia.next({
      base64_complete: null,
      description: null,
      expiration_date: null,
      expiration_hour: null,
      id: null,
      publishing_date: null,
      tags: null,
      publishing_hour: null,
      title: null
    })
  }

  getMediaById(id: string) {
    return this.http.get(`${this.getMediaEndpoint}/getmedia/resource/${id}`).pipe(tap((media: any) => this.createMedia.next({ ...media, size: `${media.size != null ? `${media.size} MB` : undefined}` })))
  }

  getCreateMedia() {
    return this.createMedia.asObservable()
  }

  deleteMedias(medias: string[]) {
    return this.http.delete(`${this.uploadMediaEndpoint}/uploadmedia/${medias.join(',')}`)
  }

  saveMedia(media: Media) {
    if (media?.larger_size != undefined && media?.larger_size === true) {
      return throwError(() => new Error("File size limit"));
    }

    if (media.id) {
      return this.http.put(`${this.uploadMediaEndpoint}/uploadmedia/updateMedia/${media?.id}`, media).pipe(tap(() => this.createMedia.next({} as Media)))
    } else {
      return this.http.post(`${this.uploadMediaEndpoint}/uploadmedia/createMedia`, { ...media, filename: undefined, height: undefined, size: undefined, width: undefined, extension: undefined } as Media).pipe(map((media) => { this.createMedia.next({} as Media); return { resource_file: media } }))
    }
  }

  mediaExtension(type) {
    if (type.includes('pdf')) {
      return 'pdf'
    } else if (type.includes('sheet') || type.includes('excel') || type.includes('csv') || type.includes('xls') || type.includes('wps') || type.includes('xlsx')) {
      return 'xls'
    } else if (type.includes('text/plain') || type.includes('rtf') || type.includes('rtx')) {
      return 'txt'
    } else if (type.includes('audio')) {
      return 'audio'
    } else if (type.includes('word')) {
      return 'doc'
    } else if (type.includes('video')) {
      return 'video'
    } else if (type.includes('powerpoint') || type.includes('presentation')) {
      return 'ppt'
    } else {
      return 'other'
    }
  }

  editAcl(aclId: string, readGroups: string[]) {
    return this.http.put(`${this.uploadMediaEndpoint}/acls/${aclId}`, { readGroups })
  }

  createAcl(mediaId: string, readGroups: string[]) {
    return this.http.post(`${this.uploadMediaEndpoint}/acls`, { mediaId, readGroups })
  }

  getMedia(mediaId) {
    return this.http.get(`${this.getMediaEndpoint}/getmedia/resource/${mediaId}`)
  }

  getBinarie(mediaId?) {
    const headers = new HttpHeaders();
    headers.set('Authorization', `Bearer ${this.token}`)
    return this.http.get(`${this.getMediaEndpoint}/getmedia/view/${mediaId}`, { headers, responseType: 'blob' })
  }

  getBinariefromUrl(url?) {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.token}`)
    return this.http.get(`${url}`, { headers, responseType: 'blob' })
  }

  getIam() {
    const headers = new HttpHeaders()
      .set('ignoreInterceptorRedirect', 'true')
    return this.http.get<Record<string, string>>(`${this.getMediaEndpoint}/oauth/iam`, { headers })
  }

  setIamToken(token: string) {
    this.iamToken$.next(token)
  }

  getIamToken() {
    if (this.loading || !!this.iamToken$.getValue()) {
      return this.iamToken$.asObservable();
    }

    if (!this.lock) {
      this.lock = true;

      return defer(() => {
        this.loading = true;

        return this.getIam()
          .pipe(
            map(resp => resp?.token),
            tap(token => this.iamToken$.next(token)),
            finalize(() => {
              this.loading = false;
              this.lock = false;
            })
          )
      })
    } else {
      return this.iamToken$.asObservable();
    }
  }

  getCDNImage(url, iam) {

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${iam}`
    })

    return this.httpClient.get(`${url}`, { headers, responseType: 'blob' }).pipe(
      catchError((err) => {
        this.iamToken$.next(null);
        return of({ error: err })
      })
    )
  }

  toDataURL(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result as string
        let encoded = result.replace(/^data:(.*;base64,)?/, '');
        if ((encoded.length % 4) > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        resolve({ result, encoded });
      };
      reader.onerror = error => reject(error);
    });
  };


  postMedia(data) {
    return this.http.post(`${this.uploadMediaEndpoint}/uploadmedia/save`, data)
  }

  downloadMedia(mediaUrl: string, mediaName: string) {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.iamToken$.getValue()}`)

    this.httpClient.get(mediaUrl, { responseType: 'blob', headers }).subscribe({
      next: (res) => {
        const url = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = url;
        link.download = mediaName;
        link.click();
      }
      , error: (err) => {
        this.iamToken$.next(null);
        return of(null)
      }
    });
  }

  saveUserProfilePhoto(photo) {
    return this.http.post(`${this.uploadMediaEndpoint}/uploadmedia/photo`, photo)
  }

  saveUserCoverPhoto(photo) {
    return this.http.post(`${this.uploadMediaEndpoint}/uploadmedia/user-cover-image`, photo)
  }

  saveUserCurriculum(curriculum) {
    return this.http.post(`${this.uploadMediaEndpoint}/uploadmedia/user-curriculum`, curriculum)
  }

  getDownloadMediaLink(key: string) {
    return `${this.getMediaEndpoint}/getmedia/resource/url/${key}`
  }

  getCDNVIdeo(media, token, video, seconds, error) {
    const url = media?.url;
    const mimeCodec = media?.mimeCodec;
    let totalSegments = 0;
    let segmentLength = 0;
    let segmentDuration = 0;
    let bytesFetched = 0;
    let requestedSegments = [];
    let fileTotalLen = 0;
    const mediaSource = new MediaSource;
    let sourceBuffer = null;
    let hasError = false;
    const checkBuffer$ = new Subject();

    checkBuffer$
      .pipe(
        throttleTime(100)
      )
      .subscribe(checkBuffer);

    if (mimeCodec == null) {
      error();
    }

    video.src = URL.createObjectURL(mediaSource);
    mediaSource.addEventListener('sourceopen', sourceOpen);

    video.addEventListener('error', onError);

    function onError(_) {
      console.log(video.error);
      if (!hasError && video?.error?.message?.includes('Detected unfragmented')) {
        error();
        hasError = true;
      } else if (!hasError) {
        const currentTime = video.currentTime;
        console.log('error', currentTime);

        error(currentTime)
      }
    }

    function sourceOpen(_) {
      sourceBuffer = mediaSource.addSourceBuffer(mimeCodec);

      getFileLength(url, function(fileLength) {
        fileTotalLen = fileLength;
        const fileInMb = (fileLength / 1024 / 1024).toFixed(2);
        console.log(fileInMb, 'MB');

        //segmentLength = Math.round(fileLength / totalSegments);

        // set segmentLength to 1mb;
        segmentLength = 1024 * 1024;

        if (segmentLength / 1024 > fileLength / 1024) {
          segmentLength = fileLength;
        }

        totalSegments = Math.ceil(fileLength / segmentLength);

        for (let i = 0; i < totalSegments; ++i) requestedSegments[i] = false;

        fetchRange(url, 0, segmentLength, appendSegment);
        requestedSegments[0] = true;
        video.addEventListener('timeupdate', (e) => checkBuffer$.next(e));
        video.addEventListener('canplay', function() {
          video.play();
        });
        video.addEventListener('waiting', function(e) {
          console.log('waiting', video.currentTime);
          checkBuffer$.next(e);

          setTimeout(() => {
            if (video.readyState === 1) {
              console.log('play')
              checkBuffer$.next(e);
            } else {
              error(video?.currentTime);
            }
          }, 500)
        });
      });
    };

    function getFileLength(url, cb) {
      let xhr = new XMLHttpRequest;
      xhr.open('get', url);
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      xhr.setRequestHeader('Range', 'bytes=0-10');
      xhr.onload = function() {
        const contentRange = xhr.getResponseHeader('content-range');
        const totalLength = contentRange.split('/')[1];
        cb(totalLength);
      };
      xhr.send();
    };

    function fetchRange(url, start, end, cb) {
      let xhr = new XMLHttpRequest;
      xhr.open('get', url);
      xhr.responseType = 'arraybuffer';
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);
      xhr.setRequestHeader('Range', 'bytes=' + start + '-' + end);
      xhr.onload = function() {
        console.log('fetched bytes: ', start, end);
        bytesFetched += end - start + 1;
        cb(xhr.response);
      };

      xhr.onerror = function(error) {
        console.log('error fetching bytes: ', start, end, error);
      };

      xhr.send();
    };

    function appendSegment(chunk) {
      if (video.error !== null) {
        return null;
      }
      sourceBuffer.appendBuffer(chunk);


      setTimeout(() => {
        if (video.readyState === 0) {
          if (!hasError) {
            video.removeEventListener('timeupdate', checkBuffer);
            video.removeEventListener('error', onError);
            error();
            hasError = true;
          }
        }

        if (segmentDuration === 0) {
          if (video.duration !== Infinity && !isNaN(video.duration)) {
            segmentDuration = video.duration / totalSegments;

            checkBuffer$.next(true)
          }
        }
      }, 300);
    };

    function checkBuffer(_) {
      let currentSegment = getCurrentSegment();

      if (currentSegment === totalSegments && haveAllSegments() && mediaSource.readyState === 'open') {
        console.log('last segment', mediaSource.readyState);
        mediaSource.endOfStream();
        video.removeEventListener('timeupdate', checkBuffer);
      } else if (shouldFetchNextSegment(currentSegment)) {
        fetchSegment(currentSegment);
      }
    };

    function fetchSegment(currentSegment) {
      const lastFoundSegment = requestedSegments.lastIndexOf(true);

      const loadBy = currentSegment - lastFoundSegment;

      if (loadBy > 1) {
        for (let i = lastFoundSegment + 1; i < currentSegment; ++i) {
          requestedSegments[i] = true;
        }
      } else {
        requestedSegments[currentSegment] = true;
      }

      console.log('time to fetch next chunk', video.currentTime);

      if (bytesFetched >= fileTotalLen) {
        console.log('no more bytes to fetch');
        return;
      }

      let nextBatch = bytesFetched + (segmentLength * loadBy);

      if (nextBatch > fileTotalLen) {
        nextBatch = fileTotalLen;
      }

      fetchRange(url, bytesFetched, nextBatch, appendSegment);
    }

    function getCurrentSegment() {
      if (segmentDuration !== 0) {
        return ((video.currentTime / segmentDuration) | 0) + 1;
      } else {
        return ((video.webkitVideoDecodedByteCount / segmentLength) | 0) + 1;
      }
    };

    function haveAllSegments() {
      return requestedSegments.every(function(val) { return !!val; });
    };

    function shouldFetchNextSegment(currentSegment) {
      if (segmentDuration !== 0) {
        return video.currentTime > segmentDuration * currentSegment * 0.8 &&
          !requestedSegments[currentSegment];
      } else {
        return video.webkitVideoDecodedByteCount > bytesFetched * 0.7 &&
          !requestedSegments[currentSegment];
      }

    };
  }

  getMaxSizeByFileType(type: string): number {
    if (this.maxFileSizeConfig?.items?.length > 0) {
      const typeConfig = this.maxFileSizeConfig.items.find(c => c.contentType === type)

      if (typeConfig != undefined) return typeConfig.maxSize;
    }

    return this.maxFileSizeConfig?.otherContentTypeMaxSize || 30;
  }

  validateMBsize(bytes: number, type: string) {

    let max_size: number = this.getMaxSizeByFileType(type);

    if (bytes === 0) return null;

    if (bytes >= 1048576) {
      bytes = parseFloat((bytes / 1048576).toFixed(2))
      if (bytes > max_size) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  getMaxFileSizeConfig() {
    return this.http.get<any>(`${this.uploadMediaEndpoint}/max-file-size-configs`)
  }
}
